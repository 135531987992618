import moment from "moment";
import { v4 as uuidv4 } from 'uuid';


const addBusinessDays = (daysToAdd) => {
  /** start date can be current date */
  let currentDate = new Date();
  while (daysToAdd > 0) {
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    // check if it is a weekday
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      // Hier könnten Sie auch prüfen, ob es sich um einen Feiertag handelt
      // if (!isHoliday(currentDate)) {
      daysToAdd--;
      // }
    }
  }
  return currentDate;

}

export const getParsedOrderV6 = (
  customer,
  products,
  address,
  orderNumber,
  totalPrice,
  deliveryDate,
  countryData
) => {
  const customFields = {
    // adds three working days to deliver_date
    shm_order_set_delivery_date: moment(addBusinessDays(3)).format("YYYY-MM-DD"),
    shm_order_set_order_number: orderNumber.replace("-", "/"),
    shm_order_set_hide_delivery_address:true,
    shm_order_set_show_company_name:true,
    shm_order_set_shop_email:true,
    shm_order_set_babyone_no: orderNumber.replace("-", "/"),
     }

  const uuid = uuidv4();
  const strippedUuid = uuid.replace(/-/g, '');

  const { taxRate } = countryData;
  const shippingMethodId = countryData.shippingMethodId || "018ad1acd76b73eca333c767cdd5c89f";
  let taxPrice = +(`0.${taxRate}`);
  //let isTaxFree = !(customer?.vatIds.length && customer?.vatIds.length > 0 && customer?.vatIds[0] !== "");

  // Determine if the customer is eligible for tax-free status.
  // Conditions for tax-free status:
  // 1. The customer has VAT IDs (i.e., customer.vatIds is not null or undefined and has a length greater than 0).
  // 2. The first VAT ID is not an empty string.
  // 3. The first VAT ID does not contain the substring "DE".
  // If all these conditions are met, isTaxFree is set to true; otherwise, it is set to false.

  let isTaxFree = !(customer?.vatIds.length &&
      customer?.vatIds.length > 0 &&
      customer?.vatIds[0] !== "" &&
      !customer.vatIds[0].includes("DE"));

  let totalTaxPrice = isTaxFree ?
    (totalPrice * taxPrice).toFixed(2) : 0;
  let taxRules = isTaxFree ? [{
    taxRate,
    //percentage: 100,
  }] : [];

  return {
    customFields,
    billingAddressId: strippedUuid,
    currencyId: "b7d2554b0ce847cd82f3ac9bd1c0dfca",
    languageId: customer.languageId,
    salesChannelId: customer.salesChannelId, //"018add95933474f6b6d5520ed99d56ed",
    stateId: "018ad12f18ef705a9b355e2d9224b11f",
    orderDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
    orderNumber,
    currencyFactor: 1,
    itemRounding: { decimals: 2, interval: 0.01, roundForNet: true },
    totalRounding: { decimals: 2, interval: 0.01, roundForNet: true },
    shm_order_set_order_number: orderNumber,
    shm_order_set_delivery_date: deliveryDate,

    affiliateCode: "BackendEdiOrderImport",
    source: "backend",
    orderCustomer: {
      customerId: customer.id,
      customerNumber: customer.customerNumber,
      email: customer.email,
      firstName: customer.firstName,
      lastName: customer.lastName,
      salutationId: customer.salutationId,
      title: null,
      vatIds: customer.vatIds,
      company: customer.company || "",
    },
    addresses: [
      {
        id: strippedUuid,
        salutationId: address.salutationId,
        company: address.company,
        department: address.department,
        firstName: address.firstName,
        lastName: address.lastName,
        additionalAddressLine1: address.additionalAddressLine1,
        additionalAddressLine2: address.additionalAddressLine2,
        street: address.street,
        zipcode: address.zipcode,
        city: address.city,
        countryId: address.countryId,
        vatId: customer.vatIds[0],
        phoneNumber: address.phoneNumber,
      },
    ],
    "tagIds": [
      "018f060dc2f278a0a825c9ce447b10cc"
    ],
    price: {
      totalPrice: (parseFloat(totalPrice) + parseFloat(totalTaxPrice)).toFixed(2), // total price including tax
      netPrice: totalPrice, // net price
      // positionPrice: (totalPrice - (totalPrice * 0.19)).toFixed(2),
      positionPrice: totalPrice, // price the same as in "netPrice"
      rawTotal: totalPrice, // price the same as in "totalPrice"
      taxStatus: isTaxFree ? "net" : "tax-free", // "net" or "gross" type of tax depending on which price we treat as the base price
      calculatedTaxes: isTaxFree ? [
        {
          tax: totalPrice * taxPrice, // tax value for the rate
          taxRate,
          price: totalPrice, // net or gross price depending on tax status
        },
      ]:[],
      taxRules,
    },
    shippingCosts: {
      unitPrice: 0.0,
      totalPrice: 0.0,
      quantity: 0,
      calculatedTaxes: isTaxFree ? [
        {
          tax: 0, // tax value for the rate
          taxRate, // tax rate in percentage
          price: totalPrice, // net or gross price depending on tax status
        },
      ] : [],
      taxRules,
    },
    lineItems: products.map((product, index) => {
      const position = index + 1; // Start positions from 1
      //let newPostion = position === 0 ? position+1 : position;
      const quantity = parseInt(product.CSV["Quantity"]);
      const unitPrice = parseFloat(product.CSV["Price per Item"]);
      const taxId = product.TaxShipping.taxId;
      return {
        productId: product.id,
        referencedId: product.id,
        payload: {
          productNumber: product.productNumber,
          taxId,
          optionIds: [...product.optionIds],
          options: product.options
        },
        identifier: product.id,
        type: "product",
        label: product.CSV["Product Name"],
        quantity,
        //position: newPostion,
        position,
        price: {
          unitPrice,
          totalPrice: unitPrice * quantity,
          quantity,
          calculatedTaxes: isTaxFree ? [
            {
              tax: 0,
              taxRate,
              price: unitPrice,
            },
          ] : [],
          taxRules,
        },
        priceDefinition: {
          type: "quantity",
          price: unitPrice,
          quantity,
          taxRules,
          listPrice: null,
          isCalculated: true,
          referencePriceDefinition: null,
        },
      };
    }),
    deliveries: [
      {
        stateId: "018ad12f18f47001871826d7835c2266",
        shippingMethodId,
        shippingOrderAddress: {
          salutationId: customer.salutationId,
          company: address.company,
          department: address.department,
          firstName: address.firstName,
          lastName: address.lastName,
          additionalAddressLine1: address.additionalAddressLine1,
          additionalAddressLine2: address.additionalAddressLine2,
          street: address.street,
          zipcode: address.zipcode,
          city: address.city, // product.CSV["City"],
          countryId: address.countryId,
          phoneNumber: "",
        },
        shippingDateEarliest: deliveryDate,
        shippingDateLatest: deliveryDate,
        shippingCosts: {
          unitPrice: 0,
          totalPrice: 0,
          quantity: 1,
          calculatedTaxes: isTaxFree ? [
            {
              tax: 0,
              taxRate,
              price: 0,
            },
          ] : [],
          taxRules,
        }
      }
    ],
    transactions: [
      {
        paymentMethodId: customer.defaultPaymentMethodId,
        amount: {
          unitPrice: 0,
          totalPrice: 0,
          quantity: 0,
          calculatedTaxes: isTaxFree ? [
            {
              tax: 0,
              taxRate,
              price: 0,
            },
          ] : [],
          taxRules,
        },
        stateId: "018ad12f18f871b594ec3dfc90cde76c",
      }
    ],
    tags: [
     {
       id: "018f060dc2f278a0a825c9ce447b10cc"
     }
    ]
  };
};